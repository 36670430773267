import React, { createContext, useState, useEffect } from "react"

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light")
  const [scrolled, setScrolled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light"
    setTheme(newTheme)
    if (typeof window !== "undefined") {
      sessionStorage.setItem("theme", newTheme) // Save theme to local storage whenever it changes
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedTheme = sessionStorage.getItem("theme")
      if (savedTheme) {
        setTheme(savedTheme)
      } else {
        // If theme is not set in session storage, default to 'light'
        sessionStorage.setItem("theme", "light")
      }

      const handleScroll = () => {
        // Check if the window scrollY position is greater than the header height
        if (window.scrollY > 50) {
          // You can adjust 100 to the height of your header
          setScrolled(true)
        } else {
          setScrolled(false)
        }
      }
      // Add event listener for scroll event
      window.addEventListener("scroll", handleScroll)

      // Cleanup: remove event listener when the component unmounts
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, scrolled, isOpen, toggleMenu, setIsOpen }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
